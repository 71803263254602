<script setup lang="ts">
import { defineProps, defineEmits, ref, computed } from "vue";
import type { IUser, IFilter } from "@/types/common";
import { storeToRefs } from "pinia";
import useAuthStore from "@/store/auth";
import { ru } from "date-fns/locale";
import dayjs from "dayjs";

const store = useAuthStore();
const { getAuth } = storeToRefs(store);

const props = defineProps<IFilter>();

const emit = defineEmits<{
  (event: "removeFilters"): void;
  (event: "generatePdfReport"): void;
  (event: "changeFilterDateFrom", date: string | null): void;
  (event: "changeFilterDateTo", date: string | null): void;
  (event: "changeFilterCompany", company: object): void;
  (event: "changeFilterAdvertiser", advertiser: object): void;
  (event: "changeFilterCompanyLead", lead: object): void;
  (event: "changeFilterPlatform", platform: object): void;
}>();

const auth: IUser = computed(() => getAuth.value);

// eslint-disable-next-line prettier/prettier
const filtredAdvertiserList = computed(() => props.advertiserList?.filter((el: any) => el.role === "advertiser"));

const sortListOptions = [
  {
    id: 0,
    title: "Все",
  },
  {
    id: 1,
    title: "Активные",
  },
  {
    id: 2,
    title: "Неактивные",
  },
  {
    id: 3,
    title: "По названию",
  },
];

const datePickFrom = ref<string | null>(null);
const datePickTo = ref<string | null>(null);
const filterCompany = ref<object | null>(null);
const filterAdvertiser = ref<object | null>(null);
const filterLead = ref<object | null>(null);
const filterPlatform = ref<object | null>(null);

const removeFilters = () => {
  emit("removeFilters");
  datePickFrom.value = null;
  datePickTo.value = null;
  filterCompany.value = null;
  filterAdvertiser.value = null;
  filterLead.value = null;
  filterPlatform.value = null;
};

const generatePdfReport = () => {
  emit("generatePdfReport");
};

const changeFilterCompany = (value: object) => {
  emit("changeFilterCompany", value);
};

const changeFilterAdvertiser = (value: object) => {
  emit("changeFilterAdvertiser", value);
};

const changeFilterCompanyLead = (value: object) => {
  emit("changeFilterCompanyLead", value);
};

const changeFilterPlatform = (value: object) => {
  emit("changeFilterPlatform", value);
};

const changeFilterDateFrom = () => {
  const date = dayjs(datePickFrom.value).format("YYYY-MM-DD");
  emit("changeFilterDateFrom", date);
};

const clearFilterDateFrom = () => {
  datePickFrom.value = null;
  emit("changeFilterDateFrom", datePickFrom.value);
};

const changeFilterDateTo = () => {
  const date = dayjs(datePickTo.value).format("YYYY-MM-DD");
  emit("changeFilterDateTo", date);
};

const clearFilterDateTo = () => {
  datePickTo.value = null;
  emit("changeFilterDateTo", datePickTo.value);
};
</script>

<template>
  <section class="filter-statistic">
    <div class="filter-statistic__right">
      <div class="filter-statistic__item">
        <h5 class="title">Период от</h5>
        <div class="wrapper">
          <VueDatePicker
            @closed="changeFilterDateFrom"
            @cleared="clearFilterDateFrom"
            v-model="datePickFrom"
            format="dd.MM.yyyy"
            :format-locale="ru"
            :dark="true"
            :enable-time-picker="false"
            cancelText="Закрыть"
            selectText="Выбрать"
          />
        </div>
      </div>
      <div class="filter-statistic__item">
        <h5 class="title">Период до</h5>
        <div class="wrapper">
          <VueDatePicker
            @closed="changeFilterDateTo"
            @cleared="clearFilterDateTo"
            v-model="datePickTo"
            format="dd.MM.yyyy"
            :format-locale="ru"
            :dark="true"
            :enable-time-picker="false"
            cancelText="Закрыть"
            selectText="Выбрать"
          />
        </div>
      </div>
      <!-- <div v-if="!!companyList" class="filter-statistic__item">
        <h5 class="title">Название кампании</h5>
        <div class="wrapper">
          <VSelect
            @update:modelValue="changeFilterCompany"
            v-model="filterCompany"
            :options="companyList"
            :clearable="false"
            :filterable="false"
            :searchable="false"
            class="select"
            placeholder="Не выбрано"
            label="title"
          />
        </div>
      </div> -->
      <div v-if="!!platformList" class="filter-statistic__item">
        <h5 class="title">Платформа</h5>
        <div class="wrapper">
          <VSelect
            @update:modelValue="changeFilterPlatform"
            v-model="filterPlatform"
            :options="platformList"
            :clearable="false"
            :filterable="false"
            :searchable="false"
            class="select"
            placeholder="Не выбрано"
            label="name"
          />
        </div>
      </div>
      <div
        v-if="
          filtredAdvertiserList?.length &&
          (auth.user.role === 'admin' || auth.user.role === 'lead')
        "
        class="filter-statistic__item"
      >
        <h5 class="title">Рекламодатель</h5>
        <div class="wrapper">
          <VSelect
            @update:modelValue="changeFilterCompanyLead"
            v-model="filterAdvertiser"
            :options="filtredAdvertiserList"
            :clearable="false"
            :filterable="true"
            :searchable="true"
            class="select"
            placeholder="Не выбрано"
            label="first_name"
          />
        </div>
      </div>

      <div class="filter-statistic__item">
        <h5 class="title">Рекламная кампания</h5>
        <div class="wrapper">
          <VSelect
            @update:modelValue="changeFilterAdvertiser"
            v-model="filterCompany"
            :options="sortListOptions"
            :clearable="false"
            :filterable="false"
            :searchable="false"
            class="select"
            placeholder="Не выбрано"
            label="title"
          />
        </div>
      </div>
    </div>
    <div class="filter-statistic__left">
      <div class="filter-statistic__item">
        <!-- <h5 class="title">Скачать в XLSX</h5> -->
        <div class="wrapper">
          <button
            @click="removeFilters"
            class="wrapper__remove-btn btn-default"
          >
            <img src="@/assets/svg/delete.svg" alt="delete" />
          </button>
          <!-- <button
            @click="generatePdfReport"
            class="wrapper__download-btn btn-default"
          >
            <img src="@/assets/svg/download.svg" alt="download" />
          </button> -->
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.dp__menu.dp__theme_dark {
  --dp-background-color: #060e20;
  --dp-menu-border-color: #d9d9d933;
}

.dp__main.dp__theme_dark {
  --dp-background-color: #060e20;
  --dp-border-color: #d9d9d933;
  --dp-input-padding: 4px 30px 4px 12px;
}

:root {
  --vs-dropdown-bg: #060e20;
  --vs-border-color: #d9d9d933;
  --vs-search-input-color: #d9d9d933;
  --vs-dropdown-option-color: #f4f4f4;
  --vs-selected-color: #fff;
  --vs-controls-color: #959595;
  --vs-dropdown-option--active-bg: #3369ff;
  --vs-search-input-color: #959595;
}
</style>

<style lang="scss">
.filter-statistic {
  display: flex;
  justify-content: space-between;

  padding: 20px 30px;
  border: 1px solid $color-form-border;
  background-color: $color-form-background;

  &__right {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 25px;
    row-gap: 15px;
  }
  &__left {
    margin-left: 30px;
    width: fit-content;
    @include flex-settings(row, center, flex-end);
  }

  &__item {
    h5 {
      margin: 0;
    }
    .wrapper {
      display: flex;

      &__remove-btn {
        width: 36px;
        background-color: $color-gray-2;
        margin-right: 7px;
      }
      &__download-btn {
        min-width: 72px;
        background-color: $color-blue;
      }
      .btn-default {
        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;
        height: 36px;
        border: transparent;
        border-radius: 8px;
      }
    }
    .title {
      color: $color-gray;
      text-align: left;
      margin-bottom: 10px;
    }
    .select {
      width: 100%;
      height: 36px;
    }
    .date-picker {
      height: 25px;
    }
  }
}
</style>
