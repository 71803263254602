import router from "@/router/index";
import axiosInstance from "@/service/api";
import tokenService from "@/service/token.service";
import useAuthStore from "@/store/auth";

const setup = () => {
  const store = useAuthStore();

  axiosInstance.interceptors.request.use(
    (config) => {
      const token = tokenService.getLocalAccessToken();
      if (token) {
        config.headers["Authorization"] = "JWT " + token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      const URL = originalConfig.url;
      if ((URL !== "login/" || URL !== "logout/") && err.response) {
        if (
          originalConfig.url === "auth/token/refresh/" &&
          err.response.status === 401
        ) {
          console.log("logout here!");
          store.logoutUser();
          return Promise.reject(err);
        }
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          const refreshToken = tokenService.getLocalRefreshToken();
          if (refreshToken) {
            try {
              const rs = await axiosInstance.post("auth/token/refresh/", {
                refresh: refreshToken,
              });
              const { token, refresh } = rs.data;

              tokenService.updateLocalAccessToken({
                access: token,
                refresh,
              });

              return axiosInstance(originalConfig);
            } catch (_error) {
              return Promise.reject(_error);
            }
          }
        }
        if (err.response.status === 404) {
          router.replace("/error-page");
        }
      }

      return Promise.reject(err);
    }
  );
};

export default setup;
