<script setup lang="ts">
import { defineProps, defineEmits, computed } from "vue";
import type { ICompanyItem } from "@/types/common";

const props = defineProps<{
  modelValue: number[];
  options: ICompanyItem[];
}>();

const emit = defineEmits<{
  (event: "update:modelValue", value: number[]): void;
}>();

const currentValue = computed({
  get() {
    return props.modelValue;
  },
  set(value: number[]) {
    emit("update:modelValue", value);
  },
});
</script>

<template>
  <div v-for="option in options" :key="option.id" class="search-item">
    <label :name="option.title" class="search-item__label">
      <span class="search-item__title">{{ option.title }}</span>
      <input
        v-model="currentValue"
        :id="option.title"
        :name="option.title"
        :value="option.id"
        class="search-item__checkbox custom-input__field field"
        type="checkbox"
      />
    </label>
  </div>
</template>

<style scoped lang="scss">
.search-item {
  margin-bottom: 10px;
  padding: 0 10px;
  &__title {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: $color-font-primary;
  }
  &__label {
    display: flex;
    justify-content: space-between;
  }
  &__checkbox {
    border-radius: 50%;
    appearance: none;
    -webkit-appearance: none;
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 2rem;
    padding: 0.1rem;
    border-radius: 0.5rem;
    border: 0.2rem solid $color-form-border-2;

    &::before {
      content: "";
      width: 0.8rem;
      height: 0.8rem;
      clip-path: polygon(
        20% 0%,
        0% 20%,
        30% 50%,
        0% 80%,
        20% 100%,
        50% 70%,
        80% 100%,
        100% 80%,
        70% 50%,
        100% 20%,
        80% 0%,
        50% 30%
      );
      transform: scale(0);
      background-color: $color-blue;
    }
    &:checked::before {
      transform: scale(1);
    }
    &:hover {
      color: black;
    }
  }
}
</style>
