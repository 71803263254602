import { defineStore } from "pinia";
import { useRouter } from "vue-router";
import tokenService from "@/service/token.service";

export default function useAuthStore() {
  const router = useRouter();

  return defineStore("auth", {
    state: () => ({
      auth: {
        loggedIn: false,
        user: {},
        token: "",
        refresh: "",
      },
    }),
    getters: {
      getAuth: (state) => state.auth,
      getuser: (state) => state.auth.user,
      getToken: (state) => state.auth.token,
      getRefreshToken: (state) => state.auth.refresh,
    },
    actions: {
      setUser(payload: {
        loggedIn: boolean;
        user: object;
        token: string;
        refresh: string;
      }) {
        this.auth.user = payload.user;
        this.auth.token = payload.token;
        this.auth.refresh = payload.refresh;
        this.auth.loggedIn = payload.loggedIn;
      },

      removeUser() {
        this.auth = {
          user: {},
          token: "",
          refresh: "",
          loggedIn: false,
        };
        tokenService.removeUser();
      },

      logoutUser() {
        this.removeUser();
        router.push("/auth");
        // try {
        //   this.removeUser();
        //   router.push("/auth");
        // } catch (e) {
        //   console.error(e);
        // }
      },

      initAuth() {
        // if (!store.auth.loggedIn) return;
        const payload = tokenService.getUser();

        if (payload) {
          const auth = {
            token: payload.token ?? null,
            user: payload.user ?? null,
            refresh: payload.refresh ?? null,
            loggedIn: payload.loggedIn ?? false,
          };
          this.setUser(auth);
        } else {
          router.push("/auth");
        }
      },
    },
  })();
}
