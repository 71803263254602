<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import { storeToRefs } from "pinia";
import {
  getCompanyList,
  getPlatformList,
  getAdvertiserList,
} from "@/service/filter.service";
import type { IUser } from "@/types/common";
import useAuthStore from "@/store/auth";
import html2pdf from "html2pdf.js";
// import VueHtml2pdf from "vue-html2pdf";
// import Vue3Html2pdf from "vue3-html2pdf";

import FilterStatistic from "@/components/home/FilterStatistic.vue";
import TableStatistic from "@/components/home/TableStatistic.vue";
import SearchModal from "@/components/modals/SearchModal.vue";
import Preloader from "@/components/common/Preloader.vue";

const store = useAuthStore();
const { getAuth } = storeToRefs(store);
const auth: IUser = computed(() => getAuth.value);

const isSearchModalVisible = ref<boolean>(false);
const isGlobalLoaded = ref<boolean>(true);
const isLoaded = ref<boolean>(true);

const defaultOption = ref({
  id: 0,
  title: "Все",
  name: "Все",
  first_name: "Все",
  role: "advertiser",
});

const companyList = ref<object | null>(null);
const initCompanyList = async () => {
  try {
    isGlobalLoaded.value = false;
    const { data } = await getCompanyList(
      auth.value.user.role === "advertiser"
        ? { advertiser_id: auth.value.user.id }
        : {}
    );

    // data?.results?.length
    //   ? (companyList.value = [defaultOption.value, ...data.results])
    //   : (companyList.value = []);

    data?.results?.length
      ? (companyList.value = [...data.results])
      : (companyList.value = []);
  } catch (e) {
    console.log(e);
  } finally {
    isGlobalLoaded.value = true;
  }
};

const loadCompanyList = async (payload: object | null = null) => {
  try {
    isLoaded.value = false;
    const { data } = payload?.advertiser_id
      ? await getCompanyList(payload)
      : await getCompanyList();

    // data?.results?.length
    //   ? (companyList.value = [defaultOption.value, ...data.results])
    //   : (companyList.value = []);

    data?.results?.length
      ? (companyList.value = [...data.results])
      : (companyList.value = []);
  } catch (e) {
    console.log(e);
  } finally {
    isLoaded.value = true;
  }
};

const platformList = ref<object | null>(null);
const loadPlatformList = async () => {
  try {
    // isGlobalLoaded.value = false;
    const { data } = await getPlatformList();
    if (data?.results?.length) {
      platformList.value = [defaultOption.value, ...data.results];
      // platformList.value = [...data.results];
    }
  } catch (e) {
    console.log(e);
  } finally {
    // isGlobalLoaded.value = true;
  }
};

const advertiserList = ref<object | null>(null);
const loadAdvertiserList = async () => {
  try {
    const { data } = await getAdvertiserList();
    if (data?.results?.length) {
      advertiserList.value = [defaultOption.value, ...data.results];
      // advertiserList.value = [...data.results];
    }
  } catch (e) {
    console.log(e);
  }
};

const filterDateFrom = ref<string | null>(null);
const filterDateTo = ref<string | null>(null);
const filterCompany = ref<string | null>(null);
const filterPlatform = ref<string | null>(null);
const filterAdvertiser = ref<number | null>(null);
const filterByActive = ref<string | null>(null);

const useParams = (): object => {
  const payload = {
    ids: filterCompany.value ?? null,
    active: filterByActive.value ?? null,
    platform: filterPlatform.value ?? null,
    advertiser_id: filterAdvertiser.value ?? null,
    event_created_gte: filterDateFrom.value ?? null,
    event_created_lte: filterDateTo.value ?? null,
  };
  return payload;
};

const removeFilters = (): void => {
  filterCompany.value = null;
  filterByActive.value = null;
  filterPlatform.value = null;
  filterAdvertiser.value = null;
  filterDateFrom.value = null;
  filterDateTo.value = null;
  loadCompanyList();
};

const generatePdfReport = (): void => {
  var element = document.getElementById("element-to-print");
  html2pdf(element);
};

const filterByCompanyNames = (companyListId: number[]) => {
  filterCompany.value = companyListId.join(", ");
  loadCompanyList(useParams());
};

const changeFilterDateFrom = (value: string) => {
  if (value === "Invalid Date") return;
  filterDateFrom.value = value;
  loadCompanyList(useParams());
};

const changeFilterDateTo = (value: string) => {
  if (value === "Invalid Date") return;
  filterDateTo.value = value;
  loadCompanyList(useParams());
};

const changeFilterCompany = (value: { id: number }) => {
  if (value.id === 0) return loadCompanyList();
  if (companyList.value) {
    filterCompany.value = companyList.value.find(
      (el: { id: number }) => el.id === value.id
    ).id;
    loadCompanyList(useParams());
  }
};

const changeFilterCompanyLead = async (value: { id: number }) => {
  filterAdvertiser.value = value.id;
  loadCompanyList(useParams());
};

const changeFilterPlatform = (value: { id: number; name: string }) => {
  if (value.id === 0) {
    loadCompanyList();
    loadPlatformList();
    return;
  }
  if (platformList.value) {
    filterPlatform.value = platformList.value.find((el: { name: string }) => {
      return el.name === value.name;
    }).name;
    loadCompanyList(useParams());
  }
};

const changeFilterAdvertiser = (value: { title: string }) => {
  switch (value.title) {
    case "Все":
      loadCompanyList();
      // loadPlatformList();
      break;
    case "Активные":
      filterByActive.value = "true";
      loadCompanyList(useParams());
      break;
    case "Неактивные":
      filterByActive.value = "false";
      loadCompanyList(useParams());
      break;
    case "По названию":
      isSearchModalVisible.value = true;
      break;
  }
};

onMounted(() => {
  initCompanyList();
  loadPlatformList();
  loadAdvertiserList();
});
</script>

<template>
  <div class="home" id="element-to-print">
    <div class="home-wrapper">
      <div v-if="isGlobalLoaded" class="home-list animate-container-open">
        <h5 class="home-title">Статистика</h5>

        <FilterStatistic
          class="home-filters"
          :companyList="companyList"
          :platformList="platformList"
          :advertiserList="advertiserList"
          @removeFilters="removeFilters"
          @generatePdfReport="generatePdfReport"
          @changeFilterDateFrom="changeFilterDateFrom"
          @changeFilterDateTo="changeFilterDateTo"
          @changeFilterCompany="changeFilterCompany"
          @changeFilterAdvertiser="changeFilterAdvertiser"
          @changeFilterCompanyLead="changeFilterCompanyLead"
          @changeFilterPlatform="changeFilterPlatform"
        />

        <div class="home-statistic">
          <template v-if="isLoaded">
            <template v-if="companyList?.length">
              <TableStatistic
                v-for="company in companyList"
                :company="company"
                :key="company.id"
              />
            </template>

            <div v-else class="home-empty">
              <h5 class="home-empty__title">Рекламные кампании отсутствуют</h5>
            </div>
          </template>
          <template v-else>
            <Preloader
              :noText="true"
              :full-height="false"
              :transparent="true"
            />
          </template>
        </div>
      </div>
      <template v-else>
        <Preloader :noText="false" :full-height="true" :transparent="true" />
      </template>
    </div>
  </div>
  <teleport to="body">
    <SearchModal
      v-model="isSearchModalVisible"
      :companyList="companyList"
      @filterByCompanyNames="filterByCompanyNames"
    />
  </teleport>
</template>

<style lang="scss" scoped>
$padding-top: 60px;

.home {
  padding-top: $padding-top;
  min-height: calc(100vh - ($padding-top + 5px));

  &-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    color: $color-font-primary;
    margin: 15px 0 20px 0;
  }

  &-wrapper {
    min-height: 80vh;
    padding: 0 45px 60px 45px;
    @include flex-settings(column, flex-start, flex-start);
  }

  &-statistic {
    position: relative;
    min-height: 50vh;
    @include flex-settings(column, flex-start, flex-start);
  }

  &-empty {
    width: 100%;
    height: 50vh;
    @include flex-settings(column, center, center);
    &__title {
      padding: 0;
      font-size: 30px;
      font-weight: 700;
      line-height: 32px;
      text-align: center;
      color: $color-font-primary;
    }
  }
}
</style>
