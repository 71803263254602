<template lang="html">
  <section :class="['preloader-loading', { 'no-mt': $props.noText }]">
    <div id="loader" />
    <div v-show="!$props.noText" :class="['loading-text']">
      <span
        v-for="(dot, index) in dots"
        :key="`dot1${index}`"
        style="opacity: 0"
      >
        .
      </span>
      <span> Загрузка </span>
      <span v-for="(dot, index) in dots" :key="`dot2${index}`"> . </span>
    </div>
    <div v-show="!$props.noProgress" :class="['progress-text']">
      ({{ $props.progress }}%)
    </div>
  </section>
</template>

<script lang="js">
export default {
  name: "vLoading",
  props: {
    noText: {
      type: Boolean,
      default: false,
    },
    noProgress: {
      type: Boolean,
      default: true,
    },
    progress: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      maxDots: 3,
      dots: [],
      timer: null,
    };
  },
  computed: {},
  mounted() {
    this.timer = setInterval(() => {
      if (this.dots.length < this.maxDots) {
        this.addDot();
      } else {
        this.clearDots();
      }
    }, 750);
  },
  unmounted() {
    clearInterval(this.timer);
  },
  methods: {
    addDot() {
      this.dots.push({});
    },
    clearDots() {
      this.dots = [];
    },
  },
};
</script>

<style scoped lang="scss">
.preloader-loading {
  width: 250px;
  height: 250px;
  position: relative;
  @include flex-settings(column, center, center);
  margin-top: -10%;

  &.no-mt {
    margin-top: 0;
  }
  .loading-text,
  .progress-text {
    @include position-settings(absolute, null, null, 0px, null);
    @include font-settings(default, 1.2em, 400);
    @include flex-settings(row, flex-start, center);
    color: #ceced4;
    text-shadow: 0px 0.3125rem 0.8125rem rgba(black, 0.29);
  }

  .progress-text {
    @include position-settings(absolute, null, null, -1.5em, null);
    font-size: 0.75em;
  }

  #loader {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100px;
    height: 100px;
    margin: -50px 0 0 -50px;
    border-radius: 50%;
    border: 5px solid transparent;
    border-top-color: $preloader-color_1;

    -webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */

    z-index: 1001;
  }
  #loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 5px solid transparent;
    border-top-color: $preloader-color_2;

    -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
  }
  #loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 5px solid transparent;
    border-top-color: $preloader-color_3;

    -webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
  }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
      -ms-transform: rotate(0deg); /* IE 9 */
      transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
    }
    100% {
      -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
      -ms-transform: rotate(360deg); /* IE 9 */
      transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
    }
  }
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
      -ms-transform: rotate(0deg); /* IE 9 */
      transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
    }
    100% {
      -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
      -ms-transform: rotate(360deg); /* IE 9 */
      transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
    }
  }
  .loaded #loader {
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
  .loaded #loader-wrapper {
    visibility: hidden;
    -webkit-transform: translateY(-100%); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: translateY(-100%); /* IE 9 */
    transform: translateY(-100%); /* Firefox 16+, IE 10+, Opera */
    -webkit-transition: all 0.3s 1s ease-out;
    transition: all 0.3s 1s ease-out;
  }
}
</style>
