class TokenService {
  getLocalRefreshToken() {
    const user = this.getUser();
    return user?.refresh;
  }

  getLocalAccessToken() {
    const user = this.getUser();
    return user?.token;
  }

  updateLocalAccessToken({
    access,
    refresh,
  }: {
    access: string;
    refresh?: string;
  }) {
    const user = this.getUser();
    user.token = access;
    user.refresh = refresh;
    this.setUser(user);
  }
  setUser(user: object) {
    localStorage.setItem("user", JSON.stringify(user));
  }
  removeUser() {
    localStorage.removeItem("user");
  }
  getUser() {
    return JSON.parse(localStorage.getItem("user") as string);
  }
}

export default new TokenService();
