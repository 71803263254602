<script setup lang="ts">
import animaService from "@/service/anima.service";

import {
  ref,
  reactive,
  computed,
  defineProps,
  withDefaults,
  defineEmits,
} from "vue";
import { changePassword } from "@/service/auth.service";
import { Form, Field, ErrorMessage } from "vee-validate";

const props = withDefaults(
  defineProps<{
    modelValue: boolean;
  }>(),
  {
    modelValue: false,
  }
);

const emit = defineEmits<{
  (event: "update:modelValue", modelValue: boolean): void;
}>();

enum EStep {
  TRY,
  RESULT,
}
const currentStep = ref<EStep>(EStep.TRY);
function changeStep(step: EStep) {
  currentStep.value = step;
}

function closeModal() {
  animaService.modalCloseAnimated("modal-password__wrapper");
  setTimeout(() => {
    emit("update:modelValue", false);
    currentStep.value = EStep.TRY;
    resultSuccess.value =
      password.old_password =
      password.new_password1 =
      password.new_password2 =
        "";
  }, 200);
}

const isModalVisible = computed(() => props.modelValue);
const password = reactive<{
  old_password: string;
  new_password1: string;
  new_password2: string;
}>({
  old_password: "",
  new_password1: "",
  new_password2: "",
});

const hideText = reactive<{
  old_password: boolean;
  new_password1: boolean;
  new_password2: boolean;
}>({
  old_password: true,
  new_password1: true,
  new_password2: true,
});

const validatePassword = (value: any): boolean | string => {
  // if (!value) {
  //   return "Это поле необходимо заполнить";
  // }
  // if (value.length <= 5) {
  //   return "Это поле должно иметь не менее 5 символов";
  // }
  return true;
};

const isErrorChange = ref<boolean>(false);
const resultSuccess = ref<string>("");
const resultError = ref<{
  old_password: string;
  new_password1: string;
  new_password2: string;
}>({
  old_password: "",
  new_password1: "",
  new_password2: "",
});

const onSubmitForm = async () => {
  isErrorChange.value = false;
  try {
    const payload = {
      old_password: password.old_password,
      new_password1: password.new_password1,
      new_password2: password.new_password2,
    };
    const { data } = await changePassword(payload);
    console.log("response", data);
    resultSuccess.value = "Пароль успешно изменён";
    changeStep(EStep.RESULT);
  } catch (e) {
    console.log(e);
    isErrorChange.value = true;
    resultError.value = e.response.data;
  }
};
</script>

<template>
  <div class="modal-password modal-password__background" v-if="isModalVisible">
    <div class="modal-password__wrapper animate-modal-open">
      <button class="modal-password__close" @click="closeModal" type="button">
        <img class="icon" src="@/assets/svg/close.svg" alt="" />
      </button>

      <Form class="modal-password__form" @submit="onSubmitForm">
        <h5 class="modal-password__form-title">Смена пароля</h5>
        <div class="modal-password__form-column">
          <div class="modal-password__form-row">
            <label class="modal-password__form-input">
              <Field
                v-model="password.old_password"
                :rules="validatePassword"
                :type="hideText.old_password ? 'password' : 'text'"
                class="modal-password__form-input-field field"
                name="oldPassword"
                placeholder="Введите пароль"
              />
              <div class="error-wrapper">
                <ErrorMessage name="oldPassword" />
              </div>

              <div class="modal-password__form-input-icon">
                <img src="@/assets/svg/lock.svg" alt="" />
              </div>

              <button
                class="modal-password__form-input-toggle"
                type="button"
                tabindex="-1"
                @click.prevent="hideText.old_password = !hideText.old_password"
              >
                <img
                  v-if="!hideText.old_password"
                  src="@/assets/svg/eye-hide.svg"
                  alt=""
                />
                <img v-else src="@/assets/svg/eye-open.svg" alt="" />
              </button>
            </label>
          </div>

          <h6 class="modal-password__form-subtitle">Придумайте новый пароль</h6>

          <div class="modal-password__form-row">
            <label class="modal-password__form-input">
              <Field
                v-model="password.new_password1"
                :rules="validatePassword"
                :type="hideText.new_password1 ? 'password' : 'text'"
                class="modal-password__form-input-field field"
                name="newPassword__1"
                placeholder="Введите новый пароль"
              />
              <div class="error-wrapper">
                <ErrorMessage name="newPassword__1" />
              </div>

              <div class="modal-password__form-input-icon">
                <img src="@/assets/svg/lock.svg" alt="" />
              </div>

              <button
                class="modal-password__form-input-toggle"
                type="button"
                tabindex="-1"
                @click.prevent="
                  hideText.new_password1 = !hideText.new_password1
                "
              >
                <img
                  v-if="!hideText.new_password1"
                  src="@/assets/svg/eye-hide.svg"
                  alt=""
                />
                <img v-else src="@/assets/svg/eye-open.svg" alt="" />
              </button>
            </label>
          </div>

          <div class="modal-password__form-row">
            <label class="modal-password__form-input">
              <Field
                v-model="password.new_password2"
                :rules="validatePassword"
                :type="hideText.new_password2 ? 'password' : 'text'"
                class="modal-password__form-input-field field"
                name="newPassword__2"
                placeholder="Повторите новый пароль"
              />
              <div class="error-wrapper">
                <ErrorMessage name="newPassword__2" />
              </div>

              <div class="modal-password__form-input-icon">
                <img src="@/assets/svg/lock.svg" alt="" />
              </div>

              <button
                class="modal-password__form-input-toggle"
                type="button"
                tabindex="-1"
                @click.prevent="
                  hideText.new_password2 = !hideText.new_password2
                "
              >
                <img
                  v-if="!hideText.new_password2"
                  src="@/assets/svg/eye-hide.svg"
                  alt=""
                />
                <img v-else src="@/assets/svg/eye-open.svg" alt="" />
              </button>
            </label>
          </div>
          <div class="modal-password__form-action">
            <div
              v-if="!!resultSuccess || !!resultError"
              class="modal-password__form-result"
            >
              <div v-if="!isErrorChange" class="success">
                {{ resultSuccess }}
              </div>
              <div v-else class="error">
                <div
                  v-if="!!resultError?.old_password"
                  class="modal-password__error-item"
                >
                  {{ resultError.old_password[0] }}
                </div>
                <div
                  v-if="!!resultError?.new_password1"
                  class="modal-password__error-item"
                >
                  {{ resultError.new_password1[0] }}
                </div>
                <div
                  v-if="!!resultError?.new_password2"
                  class="modal-password__error-item"
                >
                  {{ resultError.new_password2[0] }}
                </div>
              </div>
            </div>

            <button
              v-if="currentStep === EStep.TRY"
              :disabled="
                !(
                  !!password.old_password &&
                  !!password.new_password1 &&
                  !!password.new_password2
                )
              "
              type="submit"
              class="modal-password__submit-btn color-blue"
            >
              Сохранить
            </button>
            <button
              v-if="currentStep === EStep.RESULT"
              @click.prevent="closeModal"
              type="submit"
              class="modal-password__submit-btn color-gray"
            >
              Закрыть
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<style scoped lang="scss">
.modal-password {
  transition: all 2s;

  &__form-action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
  }

  &__form-result {
    max-width: 400px;
    margin-bottom: 15px;
    text-align: center;
    font-size: 16px;
    font-weight: 700;

    .error {
      color: $color-pink;
    }
    .success {
      color: $color-green;
    }
  }

  &__submit-btn {
    min-height: 56px;
    width: 100%;
    max-width: 400px;

    border: none;
    border-radius: 6px;
    color: $color-font-primary;
    padding: 0px 50px;

    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      background-color: darken($color-blue, 5%);
    }

    &:disabled {
      background-color: $color-gray;
      // color: $color-font-primary;
      &.color-blue,
      &.color-gray {
        background-color: $color-gray;
      }
    }

    &.color-blue {
      background-color: $color-pink;
    }
    &.color-gray {
      background-color: $color-gray-3;
    }
  }

  &__background {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    backdrop-filter: blur(5px);
  }

  &__wrapper {
    position: relative;
  }

  &__form {
    position: relative;
    width: 480px;
    min-height: 230px;
    background-color: $color-form-background;
    padding: 50px 0px;
    border: 1px solid $color-form-border-3;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @media (max-width: 586px) {
      width: 90vw;
    }

    &-row {
      width: 100%;
      & + & {
        margin-top: 20px;
      }
    }

    &-input {
      position: relative;
      height: 100%;
      display: block;

      input:-webkit-autofill,
      input:-webkit-autofill:focus {
        transition: background-color 0s 600000s, color 0s 600000s !important;
      }

      .error-wrapper {
        margin-top: 5px;
        text-align: left;
        font-size: 14px;
      }

      .field {
        height: 56px;
        width: 300px;
        padding: 0 30px 0 70px;
        border: 1px solid $color-form-border-3;
        border-radius: 8px;
        background-color: transparent;

        &:focus,
        &:active {
          border: 1px solid $color-blue;
          outline: none;
        }

        &::placeholder {
          font-size: 16px;
          color: $color-form-placeholder;
          font-weight: 500;
        }
      }

      &-icon {
        position: absolute;
        left: 20px;
        top: 15px;

        img {
          width: 28px;
        }
      }

      &-field {
        background-color: transparent;
        color: #fff;
        font: inherit;
        font-family: Montserrat, sans-serif;
        line-height: 52px;
      }

      &-toggle {
        position: absolute;
        right: 10px;
        top: 10px;
        background-color: transparent;
        border: none;
        cursor: pointer;
      }

      // input:valid {
      //   border-color: green;
      // }

      input:invalid {
        border-color: red;
      }
    }

    &-subtitle {
      color: $color-gray-4;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0.03em;
      text-align: left;
      margin-top: 30px;
      margin-bottom: 20px;
    }

    &-title {
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      text-align: center;
      color: $color-font-primary;
      margin: 15px 0 25px 0;
    }
  }

  &__close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    height: 24px;
    width: 24px;
    padding: 0;
    opacity: 0.6;
    z-index: 9;

    @media (max-width: 586px) {
      top: 10px;
      right: 10px;
    }
  }
}
</style>
