import { createApp } from "vue";

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import Select from "vue-select";
import "vue-select/dist/vue-select.css";

import App from "./App.vue";
import router from "./router";
import interceptors from "@/service/observe.service";
import pinia from "@/store";

const app = createApp(App);

app
  .use(pinia)
  .use(router)
  .component("VueDatePicker", VueDatePicker)
  .component("VSelect", Select)
  .mount("#app");

interceptors();
