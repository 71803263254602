<script setup lang="ts">
import { computed, defineProps } from "vue";
import type { ICompany, ICompanyPlatform, IPlatforms } from "@/types/common";

const props = defineProps<ICompany>();

const countByField = (platforms: IPlatforms, field: keyof ICompanyPlatform) =>
  Object.values(platforms).reduce((acc: number, curr: ICompanyPlatform) => {
    return acc + curr[field];
  }, 0);

const totalViewsCount = computed(() =>
  countByField(props.company.platforms, "views_count")
);
const totalUniqueViewsCount = computed(() =>
  countByField(props.company.platforms, "unique_views")
);
const totalRedirectCount = computed(() =>
  countByField(props.company.platforms, "redirect_count")
);
const totalUniqueRedirectCount = computed(() =>
  countByField(props.company.platforms, "unique_redirect")
);
</script>

<template>
  <section class="table-statistic__wrapper">
    <table class="table-statistic">
      <thead class="">
        <tr class="">
          <th class="bold">Рекламодатель</th>
          <th class="bold">Название кампании</th>
          <th class="bold">Статус</th>
          <th class="bold">Платформа</th>
          <th class="bold">Просмотры</th>
          <th class="bold">Просмотры (уник), шт</th>
          <th class="bold">Переходы, шт</th>
          <th class="bold">Переходы (уник)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="bold" :rowspan="Object.keys(company.platforms).length + 1">
            {{ company.advertiser.first_name }}
          </td>
          <td
            :rowspan="Object.keys(company.platforms).length + 1"
            class="company-name bold"
          >
            {{ company.title }}
          </td>
          <td
            :rowspan="Object.keys(company.platforms).length + 1"
            :class="[
              'bold rowspan-7',
              { 'company-active': company.active },
              { 'company-deactive': !company.active },
            ]"
          >
            {{ company.active ? "Aктивна" : "Неактивна" }}
          </td>
        </tr>

        <tr v-for="(platform, key) in company.platforms" :key="key">
          <td>{{ key }}</td>
          <td>{{ platform.views_count }}</td>
          <td>{{ platform.unique_views }}</td>
          <td>{{ platform.redirect_count }}</td>
          <td>{{ platform.unique_redirect }}</td>
        </tr>

        <tr>
          <td class="bold" colspan="3">ИТОГО</td>
          <td class="bold">
            {{ `Все (${Object.keys(company.platforms).length})` }}
          </td>
          <td class="bold">{{ totalViewsCount }}</td>
          <td class="bold">{{ totalUniqueViewsCount }}</td>
          <td class="bold">{{ totalRedirectCount }}</td>
          <td class="bold">{{ totalUniqueRedirectCount }}</td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<style lang="scss" scoped>
.table-statistic {
  width: 100%;
  color: $color-font-primary;
  border-collapse: collapse;
  background-color: $color-form-background;
  margin-top: 40px;

  &__wrapper {
    width: 100%;
    @include flex-settings(column, center, center);
  }

  .rowspan-7 {
    padding: 0 25px;
  }

  .company-name {
    color: $color-blue;
  }

  .company-active {
    color: $color-green;
  }
  .company-deactive {
    color: $color-pink;
  }

  td,
  th {
    border: 1px solid $color-form-border;
    min-width: 160px;
    height: 40px;
    padding: 0 5px;
  }

  th {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: $color-gray;
  }

  td {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: $color-font-primary;
  }

  .bold {
    font-weight: 600;
  }
}
</style>
