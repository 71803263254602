class AnimaService {
  modalOpenAnimated = (modalName: string) => {
    const temp = document.getElementsByClassName(modalName)[0];
    if (typeof temp !== "undefined") {
      // temp.classList.remove("animate-modal-close");
      temp.classList.add("animate-modal-open");
    }
  };

  modalCloseAnimated = (modalName: string) => {
    const temp = document.getElementsByClassName(modalName)[0];
    if (typeof temp !== "undefined") {
      // temp.classList.remove("animate-modal-open");
      temp.classList.add("animate-modal-close");
    }
  };

  containerOpenAnimated = (containerName: string) => {
    const temp = document.getElementsByClassName(containerName)[0];
    if (typeof temp !== "undefined") {
      // temp.classList.remove("animate-container-close");
      temp.classList.add("animate-container-open");
    }
  };

  containerCloseAnimated = (containerName: string) => {
    const temp = document.getElementsByClassName(containerName)[0];
    if (typeof temp !== "undefined") {
      // temp.classList.remove("animate-container-open");
      temp.classList.add("animate-container-close");
    }
  };
}

export default new AnimaService();
