<template lang="html">
  <section
    :class="[
      'preloader',
      { 'transparent-overlay': $props.transparent },
      { full: $props.fullBackground },
    ]"
    :style="{ height: height }"
  >
    <Loading
      :no-text="$props.noText"
      :no-progress="$props.noProgress"
      :progress="$props.progress"
    />
  </section>
</template>

<script lang="js">
import Loading from './Loading.vue';

export default {
  name: "vPreloader",
  components: {
    Loading,
  },
  props: {
    fullHeight: {
      type: Boolean,
      default: true,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    noText: {
      type: Boolean,
      default: false,
    },
    fullBackground: {
      type: Boolean,
      default: false,
    },
    noProgress: {
      type: Boolean,
      default: true,
    },
    progress: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      height: '100%',
    };
  },
  computed: {},
  mounted() {
    if (this.$props.fullHeight) {
      this.height = this.getWindowHeight();
    } else this.height = 'auto';
  },
  methods: {
    getWindowHeight() {
      return `${document.body.scrollHeight}px`;
    },
  },
};
</script>

<style scoped lang="scss">
.preloader {
  @include flex-settings(row, center, center);
  width: 100%;
  height: 100%;
  background-color: $preloader-overlay-color;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  z-index: 99;

  &.transparent-overlay {
    background-color: transparent;
  }
  &.full {
    background-color: rgba(#242333, 0.6);
  }
}
</style>
