<script setup lang="ts">
import animaService from "@/service/anima.service";
import { storeToRefs } from "pinia";
import {
  ref,
  computed,
  defineProps,
  withDefaults,
  defineEmits,
  onMounted,
} from "vue";
import { getCompanyList } from "@/service/filter.service";
import type { ICompanyItem, IUser } from "@/types/common";

import useAuthStore from "@/store/auth";

const store = useAuthStore();
const { getAuth } = storeToRefs(store);

import VInput from "@/components/VInput.vue";
import SearchItem from "@/components/modals/SearchItem.vue";

const props = withDefaults(
  defineProps<{
    modelValue: boolean;
    companyList: object | null;
  }>(),
  {
    modelValue: false,
    companyList: null,
  }
);

const emit = defineEmits<{
  (event: "filterByCompanyNames", companyListId: number[]): void;
  (event: "update:modelValue", modelValue: boolean): void;
}>();

const auth: IUser = computed(() => getAuth.value);
const isModalVisible = computed(() => props.modelValue);

const checkList = ref<number[]>([]);
const companyListOption = ref<ICompanyItem[]>([]);
const searchValue = ref<string>("");

const onSearchChange = async (event: { target: { value: string } }) => {
  if (!event.target.value) return initCompanyList();

  try {
    const { data } = await getCompanyList(
      auth.value.user.role === "lead"
        ? { title: event.target.value }
        : { title: event.target.value, advertiser_id: auth.value.user.id }
    );
    if (data?.results?.length) {
      companyListOption.value = [...data.results];
    }
  } catch (e) {
    console.log(e);
  }
};

const initCompanyList = async () => {
  try {
    const { data } = await getCompanyList(
      auth.value.user.role === "advertiser"
        ? { advertiser_id: auth.value.user.id }
        : {}
    );

    if (data?.results?.length) {
      companyListOption.value = [...data.results];
    }
  } catch (e) {
    console.log(e);
  }
};

const onSubmitForm = () => {
  emit("filterByCompanyNames", checkList.value);
  emit("update:modelValue", false);
};

const closeModal = () => {
  animaService.modalCloseAnimated("modal-search__wrapper");
  setTimeout(() => emit("update:modelValue", false), 200);
};

onMounted(() => {
  initCompanyList();
});
</script>

<template>
  <div class="modal-search__background" v-if="isModalVisible">
    <div class="modal-search__wrapper animate-modal-open">
      <button class="modal-search__close" @click="closeModal" type="button">
        <img class="icon" src="@/assets/svg/close.svg" alt="" />
      </button>

      <div class="modal-search__body">
        <form
          v-if="companyListOption?.length"
          class="modal-search__form"
          @submit.prevent="onSubmitForm"
        >
          <h5 class="modal-search__title">Найдите кампанию</h5>

          <VInput
            v-model="searchValue"
            @input="onSearchChange($event)"
            icon="search"
            type="search"
            placeholder="Поиск по названию кампании"
            class="modal-search__input"
          />
          <div class="modal-search__option-list">
            <SearchItem v-model="checkList" :options="companyListOption" />
          </div>

          <div class="modal-search__actions">
            <button
              type="submit"
              class="modal-search__default-button modal-search__submit-button"
            >
              Выбрать
            </button>
          </div>
        </form>

        <div v-else class="modal-search__empty-list">
          <h5 class="modal-search__title">Ничего не найдено</h5>

          <div class="modal-search__subtitle">Список кампаний пустой</div>

          <div class="modal-search__actions">
            <button
              @click="closeModal"
              class="modal-search__default-button modal-search__close-button"
            >
              Закрыть
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.modal-search {
  transition: all 2s;

  &__actions {
    width: 100%;
    margin-top: 40px;
  }

  &__default-button {
    min-height: 56px;
    width: 100%;
    max-width: 400px;

    margin-right: auto;
    margin-left: auto;

    border: none;
    border-radius: 6px;
    padding: 0 13px;

    color: #fff;
    font-family: Montserrat;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    cursor: pointer;

    &:disabled {
      background-color: $color-font-primary;
      border-color: $color-font-primary;
      opacity: 0.8;
    }
  }

  &__close-button {
    background-color: $color-gray-2;
    &:hover {
      background-color: darken($color-gray-2, 5%);
    }
  }

  &__submit-button {
    background-color: $color-blue;
    &:hover {
      background-color: darken($color-blue, 5%);
    }
  }

  &__background {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    backdrop-filter: blur(5px);
  }

  &__wrapper {
    position: relative;
    width: 480px;
    min-height: 230px;
    background-color: $color-form-background;
    padding: 60px 15px;
    border-radius: 14px;
    border: 1px solid $color-form-border-3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 586px) {
      width: 90vw;
    }
  }

  &__form {
    width: 100%;
  }

  &__body {
    font-family: Montserrat, sans-serif;
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .modal-search__title {
      color: $color-font-primary;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      text-align: center;
      margin: 0 0 30px 0;
    }
  }

  &__close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    height: 24px;
    width: 24px;
    padding: 0;
    opacity: 0.6;

    @media (max-width: 586px) {
      top: 10px;
      right: 10px;
    }
  }

  &__option-list,
  &__empty-list {
    overflow-y: auto;
    max-height: 300px;
    width: 100%;
  }

  &__input {
    margin-bottom: 20px;
  }

  &__subtitle {
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 1.2px;
    text-align: center;
    color: $color-gray-2;
  }
}
</style>
