import $api from "@/service/api";

// список кампаний
// {{host}}/api/advertising/company/?platform=persite&active=false&title=test&advertiser_id=3&event_created_gte=2024-03-05&event_created_lte=2024-03-05
export async function getCompanyList(params?: object) {
  const response = await $api.get("/advertising/company/", { params });
  return response;
}

// список рекламодателей для фильтров
export async function getAdvertiserList() {
  const response = await $api.get("/advertising/company/advertisers/");
  return response;
}

// список платформ
export async function getPlatformList() {
  const response = await $api.get("/advertising/company/platforms/");
  return response;
}

// отчет
export async function downloadReport() {
  const response = await $api.get("/advertising/company/download/");
  return response;
}
