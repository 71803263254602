<script setup lang="ts">
import { ref, defineProps, withDefaults } from "vue";

withDefaults(
  defineProps<{
    placeholder: string;
    icon: string | null;
    type: string;
  }>(),
  {
    placeholder: "Введите значение",
    icon: null,
    type: "text",
  }
);

const value = ref(null);
</script>

<template>
  <label class="custom-input">
    <input
      v-model="value"
      :type="type"
      :placeholder="placeholder"
      class="custom-input__field field"
    />

    <div v-if="icon" class="custom-input__icon">
      <img :src="require(`@/assets/svg/${icon}.svg`)" alt="" />
    </div>
  </label>
</template>

<style scoped lang="scss">
.custom-input {
  position: relative;
  height: 100%;
  width: 100%;
  display: block;

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s !important;
  }

  &__field {
    background-color: transparent;
    color: #fff;
    font: inherit;
    font-family: Montserrat, sans-serif;
    line-height: 52px;
  }

  .field {
    height: 56px;
    width: 100%;
    padding: 0 30px 0 70px;
    border: 1px solid $color-form-border-3;
    border-radius: 8px;
    background-color: transparent;

    &:focus,
    &:active {
      border: 1px solid $color-blue;
      outline: none;
    }

    &::placeholder {
      font-size: 16px;
      color: $color-form-placeholder;
      font-weight: 500;
    }
  }

  &__icon {
    position: absolute;
    left: 20px;
    top: 15px;

    img {
      width: 28px;
    }
  }

  &__toggle-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
}
</style>
