import $api from "@/service/api";

export async function authorization({
  email,
  password,
}: {
  email: string;
  password: string;
}) {
  const response = await $api.post("auth/login/", { email, password });
  return response;
}

export async function getAccountDetails() {
  return await $api.get("/auth/user/");
}

export async function changePassword({
  old_password,
  new_password1,
  new_password2,
}: {
  old_password: string;
  new_password1: string;
  new_password2: string;
}) {
  console.log("old_password", old_password);
  console.log("new_password1", new_password1);
  console.log("new_password2", new_password2);
  return await $api.post("/auth/change-password/", {
    old_password: old_password,
    new_password1: new_password1,
    new_password2: new_password2,
  });
}
