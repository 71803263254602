<script setup lang="ts">
import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import useAuthStore from "@/store/auth";
import animaService from "@/service/anima.service";

import PasswordModal from "@/components/modals/PasswordModal.vue";

import type { IUser } from "@/types/common";

const store = useAuthStore();
const { getAuth } = storeToRefs(store);
const auth: IUser = computed(() => getAuth.value);

const isPasswordModalVisible = ref<boolean>(false);

const userLogout = () => {
  animaService.containerCloseAnimated("home-list");
  setTimeout(() => store.logoutUser(), 200);
};
</script>

<template>
  <section v-if="auth?.loggedIn" class="header">
    <a href="/" class="header-logo">
      <img src="@/assets/svg/logo.svg" class="header-logo__image" alt="" />
    </a>
    <div class="header-menu">
      <button @click="userLogout" class="header-menu__action-btn">Выйти</button>
      <button
        @click="isPasswordModalVisible = true"
        class="header-menu__action-btn"
      >
        Сменить пароль
      </button>
      <div class="header-menu__logo">
        <img src="@/assets/png/VTB_Logo_2018.png" alt="" />
      </div>
    </div>
  </section>
  <teleport to="body">
    <PasswordModal v-model="isPasswordModalVisible" />
  </teleport>
</template>

<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px 30px;

  // border-bottom: 1px solid #122337;
  // background: #05162b;

  display: flex;
  justify-content: space-between;

  &-logo {
    display: flex;
    align-items: center;
    width: max-content;
    &__image {
      max-width: none;
      height: 25px;
    }
  }

  &-menu {
    display: flex;

    &__logo {
      margin-left: 20px;

      img {
        height: 30px;
      }
    }

    &__action-btn {
      color: $color-blue;
      border: 2px solid $color-blue;
      background-color: transparent;
      border-radius: 18px;
      padding: 5px 10px;
      cursor: pointer;

      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      text-align: left;

      & + & {
        margin-left: 15px;
      }

      &:hover {
        border-color: darken($color-blue, 5%);
        color: darken($color-blue, 5%);
      }
    }
  }
}
</style>
