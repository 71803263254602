<script setup lang="ts">
import VHeader from "@/components/VHeader.vue";
import { onMounted } from "vue";
import useAuthStore from "@/store/auth";

const store = useAuthStore();

onMounted(() => {
  store.initAuth();
});
</script>

<template>
  <VHeader />
  <router-view />
</template>

<style lang="scss">
body {
  margin: 0;
  background-color: $color-main-background;
  background-image: url("@/assets/png/background__auth.png");
  background-repeat: no-repeat;
}

#app {
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $color-font-secondary;
}
</style>
